import React, { createContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { getToken, removeToken, saveToken } from '../service/auth';
import { AUTH_TOKEN_KEY } from '../constants/auth';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: (token: string, callback: Function) => void;
  signOut: Function;
  token?: string | null;
  user: null | {
    name: string;
    email: string;
    id: string;
  };
};

export const AuthContext = createContext({} as AuthProps);

const isValidToken = () => {
  const token = getToken(AUTH_TOKEN_KEY);
  // JWT decode & check token validity & expiration.
  return !!token;
};

const GET_CURRENT_USER = gql`
  query getStaff {
    auth {
      getCurrentAdminUser {
        name
        email
        id
      }
    }
  }
`;

const AuthProvider = (props: any) => {
  const token = getToken(AUTH_TOKEN_KEY);
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  const { data } = useQuery(GET_CURRENT_USER, {
    skip: !isAuthenticated,
  });

  function authenticate(token: string, cb: Function) {
    makeAuthenticated(true);
    saveToken(AUTH_TOKEN_KEY, token);
    if (cb) {
      cb();
    }
  }
  function signOut(cb: Function) {
    makeAuthenticated(false);
    removeToken(AUTH_TOKEN_KEY);
    if (cb) {
      cb();
    }
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signOut,
        token,
        user: data?.auth?.getCurrentAdminUser || null,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
