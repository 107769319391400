import React, { Suspense, lazy, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';

import GraphQLProvider from 'graphql/client';
import config from 'config';

import Loader from 'components/loader';
import { PrivateRoute } from './helpers/auth';

import AuthContextProvider, { AuthContext } from 'context/auth';

import 'react-toastify/dist/ReactToastify.css';

const CustomersPage = lazy(() => import('pages/customers'));
const CustomerPage = lazy(() => import('pages/customer'));
const OfficePage = lazy(() => import('pages/office'));
const BillingPage = lazy(() => import('pages/billing'));
const LoginPage = lazy(() => import('pages/login'));

const optimizely = createInstance({
  sdkKey: config.ROLLOUT_KEY,
});

function App() {
  const { user } = useContext(AuthContext);
  return (
    <GraphQLProvider>
      <AuthContextProvider>
        <OptimizelyProvider
          optimizely={optimizely}
          user={{ id: user ? user.id : 'guest' }}
        >
          <Router>
            <Suspense fallback={<Loader />}>
              <Switch>
                <PrivateRoute path="/" exact>
                  <CustomersPage />
                </PrivateRoute>
                <PrivateRoute path="/customers/:id" exact>
                  <CustomerPage />
                </PrivateRoute>
                <PrivateRoute path="/offices/:id" exact>
                  <OfficePage />
                </PrivateRoute>
                <PrivateRoute path="/billing" exact>
                  <BillingPage />
                </PrivateRoute>
                <Route path="/login" exact>
                  <LoginPage />
                </Route>
              </Switch>
            </Suspense>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Router>
        </OptimizelyProvider>
      </AuthContextProvider>
    </GraphQLProvider>
  );
}

export default App;
