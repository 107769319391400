import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import config from 'config';
import React from 'react';
import { getToken, removeToken } from '../service/auth';
import { AUTH_TOKEN_KEY } from '../constants/auth';

const client = () =>
  new ApolloClient({
    uri: `${config.DOMAIN_PROTOCOL}api.${config.DOMAIN_SUFFIX}/graphql`,
    request: (operation) => {
      operation.setContext({
        headers: {
          Authorization: getToken(AUTH_TOKEN_KEY),
        },
      });
    },
    cache: new InMemoryCache({ addTypename: false }),
    onError: ({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err?.message) {
            case 'Unauthorized':
              removeToken(AUTH_TOKEN_KEY);
              window.location.reload();
              return forward(operation);
          }
        }
      }
    },
  });

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export default ({ children }: { children: React.ReactNode }) => (
  <ApolloProvider client={client()}>{children}</ApolloProvider>
);
