import React from 'react';
import classnames from 'classnames';

import './loader.scss';

const loader = ({ fullscreen = false }: any) => {
  return (
    <div className={classnames('loader-container', { fullscreen })}>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default loader;
